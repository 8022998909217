/*
const camelToSnakeCase = word => word.replace(/[A-Z]/g, token => `_${token.toLowerCase()}`);
const snakeToCamel = word => word.replace(/_[a-z]/g, token => `${token.charAt(1).toUpperCase()}`)
const oauthClient = {
  appName: '',
  appSummary: '',
  clientId: '',
  clientSecret: '',
  redirectUris: [],
  scopes: []
}*/

const oauthClient = {
  app_name: '',
  app_summary: '',
  client_id: '',
  client_secret: '',
  redirect_urls: [],
  scopes: [],
}

export const getOauthClient = () => {
  return { ...oauthClient }
}
