<template>
  <v-row>
    <base-dialog
      ref="dialogModal"
      title="アプリケーションを作成"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.modal_save_btn')"
      @submit="addInstance()"
    >
      <template v-slot:card-text>
        <v-form ref="formAdd" v-model="form.valid" lazy-validation>
          <v-text-field
            v-model="form.app_name"
            label="アプリケーション名"
            hint="ユーザへの利用許可時に表示されます"
            :rules="form.inputRules"
            required
          />
          <v-textarea
            v-model="form.app_summary"
            :rules="form.inputRulesSummary"
            :auto-grow="true"
            label="アプリケーション詳細"
            hint="アプリケーションの利用目的、利用内容を記入してください"
            style="font-size: 12px"
          />
        </v-form>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogOnEdit"
      :title="$t('message.confirm')"
      message="アプリの情報を更新します。よろしいですか?"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'更新'"
      @submit="editInstance()"
    />

    <base-dialog
      ref="dialogOnDelete"
      title="注意"
      message="利用ユーザを含むこのアプリケーションに紐づく全ての情報が削除されます。この操作は取り消せません、よろしいですか?"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.delete')"
      @submit="
        deleteInstance()
        $refs.dialogOnDelete.close()
      "
    />

    <v-container>
      <v-row no-gutters>
        <v-col class="ma-4">
          <v-card class="sweeep-dialog">
            <v-row>
              <v-col offset="2" cols="4">
                <strong>アプリケーション設定</strong>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selected"
                  :items="data"
                  label="アプリケーション選択"
                  hide-details
                  item-text="app_name"
                  return-object
                />
              </v-col>
            </v-row>
            <v-col
              v-if="selected && selected.app_name"
              cols="10"
              offset="1"
              class="mt-4"
            >
              <v-card-text>
                <v-form ref="formEdit" v-model="form.valid">
                  <v-card class="pa-4">
                    <v-card-title class="mb-4">
                      アプリケーション情報
                    </v-card-title>
                    <v-text-field
                      v-model="selected.app_name"
                      label="アプリケーション名"
                    />
                    <v-textarea
                      v-model="selected.app_summary"
                      name="input-7-1"
                      filled
                      label="アプリ詳細"
                      outline
                    />
                    <v-divider />
                    <v-text-field
                      v-model="selected.client_id"
                      label="Client ID"
                      readonly
                      outline
                    />
                    <v-text-field
                      v-model="selected.client_secret"
                      label="Client Secret"
                      outline
                      readonly
                      :append-icon="
                        show.clientSecret ? 'visibility' : 'visibility_off'
                      "
                      :type="show.clientSecret ? 'text' : 'password'"
                      @click:append="show.clientSecret = !show.clientSecret"
                    />
                    <v-text-field
                      :value="selected.oauth_endpoint"
                      label="oauth認証url"
                      append-icon="zmdi-copy"
                      @click.stop.prevent="writeToClipboard()"
                    />
                    <input
                      id="oauth-endpoint"
                      :value="selected.oauth_endpoint"
                      type="hidden"
                    />
                  </v-card>
                  <v-card outlined tile class="mt-4 pa-4">
                    <v-card-title class="mb-4">
                      アプリケーション設定
                    </v-card-title>
                    <v-text-field
                      v-for="(_, index) in selected.redirect_urls"
                      :key="`redirectUri${index}`"
                      v-model="selected.redirect_urls[index]"
                      label="リダイレクト用url"
                      :rules="[
                        ...form.redirectUriRules,
                        !hasDuplicateUris || '重複するURLが存在します。',
                      ]"
                    >
                      <template #append-outer>
                        <v-btn
                          v-tooltip="'Scopeを削除'"
                          icon
                          small
                          :disabled="index === 0"
                          @click.prevent="
                            selected.redirect_urls.splice(index, 1)
                          "
                        >
                          <v-icon>ti-trash</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                    <v-chip
                      v-tooltip="'リダイレクトURLを追加'"
                      @click.prevent="selected.redirect_urls.push('')"
                    >
                      <v-icon small v-text="'feather-plus'" />
                    </v-chip>
                    <v-card-text>
                      <b>Scopeの設定</b>
                    </v-card-text>
                    <span
                      >このアプリケーションがアクセスできる権限を設定してください</span
                    >
                    <v-row class="mt-4">
                      <v-col cols="3"> Scope名 </v-col>
                      <v-col cols="9"> 説明 </v-col>
                      <v-col cols="12" class="mt-4">
                        <v-row
                          v-for="(scope, index) in selected.scopes"
                          :key="'scope' + scope.id"
                        >
                          <v-col cols="3" v-text="scope.name" />
                          <v-col cols="7" v-text="scope.description" />
                          <v-col cols="2">
                            <v-btn
                              v-tooltip="'Scopeを削除'"
                              icon
                              small
                              @click.stop="removeScope(index, scope)"
                            >
                              <v-icon>ti-trash</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-show="scopes.length"
                          v-model="selectedScope"
                          :items="scopes"
                          :item-text="getNameDescription"
                          return-object
                          @change="addScope($event)"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="onOpenUpdateDialog()">
                  更新
                </v-btn>
                <v-btn color="error" @click="$refs.dialogOnDelete.open()">
                  削除
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-col v-else offset="8" cols="2">
              <v-btn
                class="ma-4"
                color="primary"
                @click.native="$refs.dialogModal.open()"
              >
                アプリケーションを作成
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'
import { getOauthClient } from 'Helpers/public_api/objects'
export default {
  data() {
    return {
      data: [],
      selected: {
        ...getOauthClient(),
      },
      selectedScope: null,
      scopes: [],
      headers: [],
      show: {
        clientSecret: false,
      },
      form: {
        valid: false,
        app_name: '',
        app_summary: '',
        inputRules: [
          (v) => !!v || '入力は必須です。',
          (v) =>
            (v && v.replace(/\s/g, '').length >= 1) ||
            this.$t('message.form_input_required'),
          (v) => v.length < 50 || '50' + '文字以内で入力してください',
        ],
        inputRulesSummary: [
          (v) => v.length < 255 || '255' + '文字以内で入力してください',
        ],
        redirectUriRules: [
          (v) => !!v || 'リダイレクトurlは必須です',
          (v) =>
            v.indexOf('https:') === 0 ||
            'SSL/TLSに対応した URLを指定してください。',
        ],
      },
    }
  },
  computed: {
    hasDuplicateUris() {
      if (!this.selected) {
        return false
      }
      const set = new Set(this.selected.redirect_urls)
      return this.selected.redirect_urls.length !== set.size
    },
  },
  mounted() {
    this.getApplications()
  },
  methods: {
    getApplications() {
      Axios.get(this.$store.getters.apiApplicationListUrl, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: { organization_id: this.$store.getters.getOrganizationID },
      }).then((response) => {
        if ('error' in response.data) {
          Snackbar.error(response.data.error)
        } else {
          this.data = response.data.apps
          if (this.data && !!this.data.length) {
            this.setApplication(this.data[0])
            const ScopeIds = this.selected.scopes.map((v) => {
              return v.id
            })
            this.scopes = response.data.scopes.filter((v) => {
              return ScopeIds.indexOf(v.id) === -1
            })
          }
        }
      })
    },
    resetForm() {
      this.form.app_name = ''
      this.form.app_summary = ''
      this.$refs.formAdd.resetValidation()
    },
    deleteInstance() {
      const params = {
        client_id: this.selected.client_id,
        client_secret: this.selected.client_secret,
      }
      Axios.delete(this.$store.getters.apiGetApplicationInstanceUrl, {
        params: params,
        headers: { Authorization: this.$store.getters.getAuthToken },
      }).then((response) => {
        if ('error' in response.data) {
          Snackbar.error(response.data.error)
        } else {
          this.$refs.dialogOnDelete.close()
          Snackbar.success(this.$t('message.success'))
          this.selected = {}
        }
      })
    },
    editInstance() {
      if (this.$refs.formEdit.validate()) {
        Axios.put(
          this.$store.getters.apiGetApplicationInstanceUrl,
          this.selected,
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if ('error' in response.data) {
            this.$refs.dialogOnEdit.close()
            Snackbar.error(response.data.error)
          } else {
            this.$refs.dialogOnEdit.close()
            Snackbar.success(this.$t('message.success'))
            this.setApplication(response.data.data)
          }
        })
      }
    },
    addInstance() {
      if (this.$refs.formAdd.validate()) {
        Axios.post(
          this.$store.getters.apiGetApplicationInstanceUrl,
          {
            app_name: this.form.app_name,
            app_summary: this.form.app_summary,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        ).then((response) => {
          if ('error' in response.data) {
            Snackbar.error(response.data.error)
          } else {
            this.setApplication(response.data.data)
            this.scopes = response.data.scopes
            this.$refs.dialogModal.close()
            Snackbar.success(this.$t('message.success'))
            this.resetForm()
          }
        })
      }
    },
    addScope(scope) {
      try {
        if (!scope) {
          return
        }
        this.selected.scopes.push(scope)
        const index = this.scopes.findIndex((v) => v.id === scope.id)
        this.scopes.splice(index, 1)
      } catch (error) {
        console.log(error)
      }
    },
    setApplication(responseApplication) {
      for (const [key, value] of Object.entries(responseApplication)) {
        if (key == 'redirect_urls' && value.length === 0) {
          value.push('')
        }
        this.selected[key] = value
      }
    },
    removeScope(index, scope) {
      this.selected.scopes.splice(index, 1)
      this.scopes.push(scope)
    },
    writeToClipboard() {
      let oauthEndpoint = document.querySelector('#oauth-endpoint')
      oauthEndpoint.setAttribute('type', 'text')
      oauthEndpoint.select()
      document.execCommand('copy')
      const options = {
        text: 'コピーに成功しました',
        timeout: 2000,
        center: true,
        top: true,
        color: 'success',
      }
      Snackbar.show(options)
      oauthEndpoint.setAttribute('type', 'hidden')
    },
    getNameDescription(item) {
      return `${item.name} ${item.description}`
    },
    onOpenUpdateDialog() {
      if (this.$refs.formEdit.validate()) {
        this.$refs.dialogOnEdit.open()
      } else {
        const message = '入力内容に誤りがあります。再度ご確認をお願いします。'
        Snackbar.error(message)
      }
    },
  },
}
</script>
